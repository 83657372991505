import Header from "Components/MainPage/Header";
import HeaderUp from "Components/MainPage/HeaderUp";
import Categories from "Components/MainPage/Categories";
import MainCategories from "Components/MainPage/MainCategories";
import Favorites from "Components/MainPage/Favorites";
import MainImage from "Components/MainPage/MainImage";
import Footer from "Components/MainPage/Footer";

import Product from "Components/ProductsPage/Product";
import SignIn from "Components/SignPage/SignIn";
import SignUp from "Components/SignPage/SignUp";

import Account from "Components/AccountPage/Account"

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function MainPage() {
  return (
    <Router>
      <HeaderUp />
      <Header />
      <Categories />
      <Switch>
        <Route path="/Girisyap">
          <SignIn />
        </Route>
        <Route path="/Kayitol">
          <SignUp />
        </Route>
        <Route path="/Hesabim">
          <Account />
        </Route>
        
        <Route path="/Urunlerimiz">
          <MainCategories />
          <Product />
        </Route>
        <Route path="/Hikayemiz">
    
        </Route>
        <Route path="/Iletisim">
          
        </Route>
        <Route path="/">
          <MainCategories />
          <MainImage />
          <Favorites />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default MainPage;
