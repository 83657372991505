import React from 'react';

export default function Sarkuteri() {
    const [state, setState] = React.useState({
        tahin : false,
        pekmez : false,
        helva : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return(
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-red-600 rounded-t-lg pt-4     font-Lobster text-bgBlue text-2xl">Şarküteri Filtrele</div>
                <svg className="fill-current text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,160L24,170.7C48,181,96,203,144,181.3C192,160,240,96,288,64C336,32,384,32,432,53.3C480,75,528,117,576,165.3C624,213,672,267,720,293.3C768,320,816,320,864,309.3C912,299,960,277,1008,240C1056,203,1104,149,1152,112C1200,75,1248,53,1296,74.7C1344,96,1392,160,1416,192L1440,224L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                
                </div>
            </div>
        </div>
    )
};