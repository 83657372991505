import React from 'react';
import { Link } from 'react-router-dom';
import 'index.css';


export default function Categories() {
    return(
            <div className="h-9 hidden md:flex md:relative w-full bottom-0 z-10 items-center bg-categoriesUp border-b border-gray-500">
                <div className="container h-full flex mx-auto items-center xl:px-96 justify-between text-lg font-light">
                    <Link className="hover:text-xl duration-150" to="/">Ana Sayfa</Link>
                    <Link className="hover:text-xl duration-150" to="/Urunlerimiz">Ürünlerimiz</Link>
                    <Link className="hover:text-xl duration-150" to="/Hikayemiz">Hikayemiz</Link>
                    <Link className="hover:text-xl hover:inline-flex duration-150" to="/Iletisim">Konumlar ve İletişim</Link>
                </div>
            </div>
    )
};