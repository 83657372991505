import React from 'react';

export default function Recelvemarmelat() {
    const [state, setState] = React.useState({
        recel : false,
        marmelat : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return (
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-pink-600 rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">Reçel ve Marmelat Filtrele</div>
                <svg className="fill-current text-pink-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,64L34.3,69.3C68.6,75,137,85,206,90.7C274.3,96,343,96,411,128C480,160,549,224,617,229.3C685.7,235,754,181,823,176C891.4,171,960,213,1029,213.3C1097.1,213,1166,171,1234,160C1302.9,149,1371,171,1406,181.3L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Kategori</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="recel" id="recel" checked={state.recel} onChange={handleChange} />
                                <label htmlFor="recel" className="ml-2 selection:bg-transparent">Reçel</label>
                            </label>
                            <label>
                                <input type="checkbox" name="marmelat" id="marmelat" checked={state.marmelat} onChange={handleChange} />
                                <label htmlFor="marmelat" className="ml-2 selection:bg-transparent">Marmelat</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};