import React from 'react';

export default function Peynir() {
    const [state, setState] = React.useState({
        inekSut : false,
        koyunSut : false,
        keciSut : false, 

        ekmekUstuSert : false,
        lokumSert : false,
        normalSert : false,
        sertSert : false,

        yağsız : false,
        yarımYağlı : false,
        tamYağlı : false,
        
        tulum : false,
        ezine : false,
        klasik : false,
        tereyağı : false,
        yoğurt : false,
        kaşar : false,
        yöresel : false,
        
        normalTuz : false,
        azTuz : false,
        yokTuz : false,

        ay6 : false,
        ay12 : false,
        ay24 : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return (
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">   
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-yellow-600 rounded-t-lg pt-4  font-Lobster text-bgBlue text-2xl  selection:bg-transparent">Peynir Filtrele</div>
                <svg className="fill-current text-yellow-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,224L48,229.3C96,235,192,245,288,229.3C384,213,480,171,576,170.7C672,171,768,213,864,245.3C960,277,1056,299,1152,272C1248,245,1344,171,1392,133.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Kategori</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="tulum" id="tulum" checked={state.tulum} onChange={handleChange} />
                                <label htmlFor="tulum" className="ml-2 selection:bg-transparent">Tulum</label>
                            </label>
                            <label>
                                <input type="checkbox" name="ezine" id="ezine" checked={state.ezine} onChange={handleChange} />
                                <label htmlFor="ezine" className="ml-2 selection:bg-transparent">Ezine</label>
                            </label>
                            <label>
                                <input type="checkbox" name="klasik" id="klasik" checked={state.klasik} onChange={handleChange} />
                                <label htmlFor="klasik" className="ml-2 selection:bg-transparent">Klasik</label>
                            </label>
                            <label>
                                <input type="checkbox" name="tereyağı" id="tereyağı" checked={state.tereyağı} onChange={handleChange} />
                                <label htmlFor="tereyağı" className="ml-2 selection:bg-transparent">Tereyağı</label>
                            </label>
                            <label>
                                <input type="checkbox" name="yoğurt" id="yoğurt" checked={state.yoğurt} onChange={handleChange} />
                                <label htmlFor="yoğurt" className="ml-2 selection:bg-transparent">Yoğurt</label>
                            </label>
                            <label>
                                <input type="checkbox" name="kaşar" id="kaşar" checked={state.kaşar} onChange={handleChange} />
                                <label htmlFor="kaşar" className="ml-2 selection:bg-transparent">Kaşar</label>
                            </label>
                            <label>
                                <input type="checkbox" name="yöresel" id="yöresel" checked={state.yöresel} onChange={handleChange} />
                                <label htmlFor="yöresel" className="ml-2 selection:bg-transparent">Yöresel</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Peynir İçeriği</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="inekSut" id="inekSut" checked={state.inekSut} onChange={handleChange} />
                                <label htmlFor="inekSut" className="ml-2 selection:bg-transparent">İnek Sütü</label>
                            </label>
                            <label>
                                <input type="checkbox" name="koyunSut" id="koyunSut" checked={state.koyunSut} onChange={handleChange} />
                                <label htmlFor="koyunSut" className="ml-2 selection:bg-transparent">Koyun Sütü</label>
                            </label>
                            <label>
                                <input type="checkbox" name="keciSut" id="keciSut" checked={state.keciSut} onChange={handleChange} />
                                <label htmlFor="keciSut" className="ml-2 selection:bg-transparent">Keçi Sütü</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Sertlik Düzeyi</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="ekmekUstuSert" id="ekmekUstuSert" checked={state.ekmekUstuSert} onChange={handleChange} />
                                <label htmlFor="ekmekUstuSert" className="ml-2 selection:bg-transparent">Ekmek Üstü</label>
                            </label>
                            <label>
                                <input type="checkbox" name="lokumSert" id="lokumSert" checked={state.lokumSert} onChange={handleChange} />
                                <label htmlFor="lokumSert" className="ml-2 selection:bg-transparent">Lokum</label>
                            </label>
                            <label>
                                <input type="checkbox" name="normalSert" id="normalSert" checked={state.normalSert} onChange={handleChange} />
                                <label htmlFor="normalSert" className="ml-2 selection:bg-transparent">Normal</label>
                            </label>
                            <label>
                                <input type="checkbox" name="sertSert" id="sertSert" checked={state.sertSert} onChange={handleChange} />
                                <label htmlFor="sertSert" className="ml-2 selection:bg-transparent">Sert</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Yağ Miktarı</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="yağsız" id="yağsız" checked={state.yağsız} onChange={handleChange} />
                                <label htmlFor="yağsız" className="ml-2 selection:bg-transparent">Yağsız</label>
                            </label>
                            <label>
                                <input type="checkbox" name="yarımYağlı" id="yarımYağlı" checked={state.yarımYağlı} onChange={handleChange} />
                                <label htmlFor="yarımYağlı" className="ml-2 selection:bg-transparent">Yarım Yağlı</label>
                            </label>
                            <label>
                                <input type="checkbox" name="tamYağlı" id="tamYağlı" checked={state.tamYağlı} onChange={handleChange} />
                                <label htmlFor="tamYağlı" className="ml-2 selection:bg-transparent">Tam Yağlı</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Tuz Miktarı</h6>
                        <div className="grid col-auto ml-6">
                            <label>
                                <input type="checkbox" name="normalTuz" id="normalTuz" checked={state.normalTuz} onChange={handleChange} />
                                <label htmlFor="normalTuz" className="ml-2 selection:bg-transparent">Normal Tuzlu</label>
                            </label>
                            <label>
                                <input type="checkbox" name="azTuz" id="azTuz" checked={state.azTuz} onChange={handleChange} />
                                <label htmlFor="azTuz" className="ml-2 selection:bg-transparent">Az Tuzlu</label>
                            </label>
                            <label>
                                <input type="checkbox" name="yokTuz" id="yokTuz" checked={state.yokTuz} onChange={handleChange} />
                                <label htmlFor="yokTuz" className="ml-2 selection:bg-transparent">Tuzsuz</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4 ">
                    <div><h6 className="font-bold selection:bg-transparent">Dinlendirilme</h6>
                        <div className="grid col-auto ml-6">
                            <label>
                                <input type="checkbox" name="ay6" id="ay6" checked={state.ay6} onChange={handleChange} />
                                <label htmlFor="ay6" className="ml-2 selection:bg-transparent">6 Ay</label>
                            </label>
                            <label>
                                <input type="checkbox" name="ay12" id="ay12" checked={state.ay12} onChange={handleChange} />
                                <label htmlFor="ay12" className="ml-2 selection:bg-transparent">1 Yıl</label>
                            </label>
                            <label>
                                <input type="checkbox" name="ay24" id="ay24" checked={state.ay24} onChange={handleChange} />
                                <label htmlFor="ay24" className="ml-2 selection:bg-transparent">2 Yıl</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};