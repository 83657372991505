import React from 'react';

export default function Tahinpekmezvehelva() {
    const [state, setState] = React.useState({
        tahin : false,
        pekmez : false,
        helva : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }
    
    return(
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center    bg-purple-600 rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">Tahin, Pekmez ve Helva Filtrele</div>
                <svg className="fill-current text-purple-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,32L34.3,42.7C68.6,53,137,75,206,122.7C274.3,171,343,245,411,261.3C480,277,549,235,617,197.3C685.7,160,754,128,823,101.3C891.4,75,960,53,1029,53.3C1097.1,53,1166,75,1234,122.7C1302.9,171,1371,245,1406,282.7L1440,320L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Kategori</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="tahin" id="tahin" checked={state.tahin} onChange={handleChange} />
                                <label htmlFor="tahin" className="ml-2 selection:bg-transparent">Tahin</label>
                            </label>
                            <label>
                                <input type="checkbox" name="pekmez" id="pekmez" checked={state.pekmez} onChange={handleChange} />
                                <label htmlFor="pekmez" className="ml-2 selection:bg-transparent">Pekmez</label>
                            </label>
                            <label>
                                <input type="checkbox" name="helva" id="helva" checked={state.helva} onChange={handleChange} />
                                <label htmlFor="helva" className="ml-2 selection:bg-transparent">Helva</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};