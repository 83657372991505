import BrandWhite from 'image/KayaWhite.png';
import BrandBlack from 'image/KayaBlack.png';

import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <div className="md:bg-gradient-to-r md:to-yellow-400 md:from-black  bg-bgBlue  box-content">
            <div id='web' className='hidden h-32 md:flex justify-center items-center align-middle'>
                <Link to="/" className="flex justify-between mx-auto px-1">
                    <img src={BrandWhite} alt="KAYA"></img>
                </Link>
                <div className="flex mx-auto">
                    <input className="flex justify-between item-center pr-32 pl-3 text-lg font-semibold rounded-tl-lg rounded-bl-lg bg-gray-300 shadow-sm focus:outline-orange" type="text" name="" id=""></input>
                    <button className="flex justify-between text-white font-semibold font-Rubik text-center transform scale-105 rounded-lg hover:rounded-3xl hover:scale-125 transition-all duration-500 -translate-x-2 bg-yellow-600 items-center p-1 pr-3 drop-shadow-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30">
                            <path d="M13 3C7.4889971 3 3 7.4889971 3 13C3 18.511003 7.4889971 23 13 23C15.396508 23 17.597385 22.148986 19.322266 20.736328L25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969L20.736328 19.322266C22.148986 17.597385 23 15.396508 23 13C23 7.4889971 18.511003 3 13 3 z M 13 5C17.430123 5 21 8.5698774 21 13C21 17.430123 17.430123 21 13 21C8.5698774 21 5 17.430123 5 13C5 8.5698774 8.5698774 5 13 5 z" fill="#FFFFFF"/>
                        </svg>
                        ARA
                    </button>
                </div>
                <div className='flex mx-auto'></div>
            </div>
            <div id='mobil' className='md:hidden'>
                <div className='h-28 flex justify-center items-center align-middle'>
                    <div className='md:hidden flex justify-between mx-auto'>
                        <button className='bg-white/25 rounded-md p-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="40" height="40">
                                <path d="M6 9 A 2.0002 2.0002 0 1 0 6 13L42 13 A 2.0002 2.0002 0 1 0 42 9L6 9 z M 6 22 A 2.0002 2.0002 0 1 0 6 26L42 26 A 2.0002 2.0002 0 1 0 42 22L6 22 z M 6 35 A 2.0002 2.0002 0 1 0 6 39L42 39 A 2.0002 2.0002 0 1 0 42 35L6 35 z"/>
                            </svg>
                        </button>
                    </div>
                    <Link to="/" className="flex mx-auto h-3/4">
                        <img src={BrandBlack} alt="KAYA"></img>
                    </Link>
                    <div className="md:hidden flex justify-between mx-auto">
                        <Link to="/Girisyap" className='bg-white/25 rounded-md p-1 m-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40" height="40">
                                <path d="M15 3C11.686 3 9 5.686 9 9L9 10C9 13.314 11.686 16 15 16C18.314 16 21 13.314 21 10L21 9C21 5.686 18.314 3 15 3 z M 14.998047 19C10.992047 19 5.8520469 21.166844 4.3730469 23.089844C3.4590469 24.278844 4.329125 26 5.828125 26L24.169922 26C25.668922 26 26.539 24.278844 25.625 23.089844C24.146 21.167844 19.004047 19 14.998047 19 z"/>
                            </svg>
                        </Link>
                        <Link to="/Kayitol" className='bg-white/25 rounded-md p-1 m-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40" height="40">
                                <path d="M13 3C7.4889971 3 3 7.4889971 3 13C3 18.511003 7.4889971 23 13 23C15.396508 23 17.597385 22.148986 19.322266 20.736328L25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969L20.736328 19.322266C22.148986 17.597385 23 15.396508 23 13C23 7.4889971 18.511003 3 13 3 z M 13 5C17.430123 5 21 8.5698774 21 13C21 17.430123 17.430123 21 13 21C8.5698774 21 5 17.430123 5 13C5 8.5698774 8.5698774 5 13 5 z"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className='block'>
                    <div className="flex justify-center">

                    </div>                    
                </div>
            </div>
        </div>
    )  
};