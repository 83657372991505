import React from 'react';

export default function Bal() {
    const [state, setState] = React.useState({
        cicekBal : false,
        camBal : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return (
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-yellow-500  rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">
                    Bal Filtrele
                </div>
                <svg className="text-yellow-500 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,224L48,208C96,192,192,160,288,160C384,160,480,192,576,181.3C672,171,768,117,864,122.7C960,128,1056,192,1152,186.7C1248,181,1344,107,1392,69.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Kategori</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="cicekBal" id="cicekBal" checked={state.cicekBal} onChange={handleChange} />
                                <label htmlFor="cicekBal" className="ml-2 selection:bg-transparent">Çiçek Balı</label>
                            </label>
                            <label>
                                <input type="checkbox" name="camBal" id="camBal" checked={state.camBal} onChange={handleChange} />
                                <label htmlFor="camBal" className="ml-2 selection:bg-transparent">Çam Balı</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};