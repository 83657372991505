import React from 'react';

export default function Kurugidavebaharatlar() {
    const [state, setState] = React.useState({
        
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return(
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-gray-600 rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">Kuru Gıda ve Baharat Filtrele</div>
                <svg className="fill-current text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,160L10.9,165.3C21.8,171,44,181,65,202.7C87.3,224,109,256,131,245.3C152.7,235,175,181,196,154.7C218.2,128,240,128,262,160C283.6,192,305,256,327,272C349.1,288,371,256,393,245.3C414.5,235,436,245,458,224C480,203,502,149,524,128C545.5,107,567,117,589,112C610.9,107,633,85,655,85.3C676.4,85,698,107,720,112C741.8,117,764,107,785,101.3C807.3,96,829,96,851,90.7C872.7,85,895,75,916,80C938.2,85,960,107,982,112C1003.6,117,1025,107,1047,101.3C1069.1,96,1091,96,1113,133.3C1134.5,171,1156,245,1178,277.3C1200,309,1222,299,1244,250.7C1265.5,203,1287,117,1309,69.3C1330.9,21,1353,11,1375,21.3C1396.4,32,1418,64,1429,80L1440,96L1440,0L1429.1,0C1418.2,0,1396,0,1375,0C1352.7,0,1331,0,1309,0C1287.3,0,1265,0,1244,0C1221.8,0,1200,0,1178,0C1156.4,0,1135,0,1113,0C1090.9,0,1069,0,1047,0C1025.5,0,1004,0,982,0C960,0,938,0,916,0C894.5,0,873,0,851,0C829.1,0,807,0,785,0C763.6,0,742,0,720,0C698.2,0,676,0,655,0C632.7,0,611,0,589,0C567.3,0,545,0,524,0C501.8,0,480,0,458,0C436.4,0,415,0,393,0C370.9,0,349,0,327,0C305.5,0,284,0,262,0C240,0,218,0,196,0C174.5,0,153,0,131,0C109.1,0,87,0,65,0C43.6,0,22,0,11,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                
                </div>  
            </div>
        </div>
    )
};