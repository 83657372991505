import React, {createContext} from 'react';
import axios from 'axios'; //Kullanmamın sebebi; fetch kullanınca önce json'a çevirir. Bu axios.get - axios.post ile hızlı olur.

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useHistory } from 'react-router-dom';

export let girisKarar = createContext();
export default function SignIn() {
    const googleClientId = '702821326345-olagm3loho6f5chps2pv9eps6rpvf8jf.apps.googleusercontent.com';
    const facebokClientId = '219156266325470';

    const history = useHistory(); //Önceki Sayfayı Öğrenmek İçin...

    const [state, setState] = React.useState({
        sigupPrivateKey : '',   //Bu kullanıcının, giriş yaptığında o anda oluşturlacak olan kodu. Aşağıdaki şifre eşleştirme kısımları bunu kontrol edecek. Kod ServerApi.js dosyasında verilecek.
        signupEmail : '',       //Bozmamak için signup.js kısmını ellemiyorum. Başlamadan önce ServerApi, Signup, Signin dosyalarının çalışan yedeklerini al. 
        signupPassword : '',    //sigupPrivateKey kısmı yeni eklendi silebilirsin.
    });

    const [errors, setErrors] = React.useState({
        signupEmail : '',
        signupPassword : '',
    });

    const [passwordShown, setPasswordShown] = React.useState(false);
    const tagglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name] : e.target.value
        });
    };
    
    let girisKarar = true;
    const onSuccess = (res) => {
        try {
            res.preventDefault();
        }
        catch {
            
        }

        if(res.profileObj != undefined) {
            console.log(res.profileObj);
            //Google
            state.signupEmail = res.profileObj.email;
            state.signupName = res.profileObj.givenName;
            state.signupSurname = res.profileObj.familyName;
            state.signupPassword = "[Google Auth]";
        }
        else {
            //Facebook
            console.log(res);
        }
        
        //Token Oluşturma Kısmı
        //WebToken(state.signupEmail, state.signupPassword);
        
        var girisMesaj = "";
        axios.get(  /*API_URL+*/"/USERS")
        .then(res => {
            res.data.forEach(element => {
                if(element.state.signupEmail == state.signupEmail && element.state.signupPassword == state.signupPassword) {
                    //Burada Sisteme Giriş Yapılır.
                    girisKarar = true;
                    
                    girisMesaj = "Kullanıcı Girişi Başarılı";
                    console.log(element.state.signupEmail);
                    history.goBack();
                }
                else if(element.state.signupEmail == state.signupEmail && element.state.signupPassword == "[Google Auth]") {
                    girisMesaj = "Lütfen Google ile giriş yapınız!";
                    girisKarar = false;
                }
            });        
            if(!girisKarar && girisMesaj == "") {
                girisMesaj = "E-posta veya Şifre hatalı";
                girisKarar = false;
            }
            console.log(girisMesaj);
        }) 
    }
    
    const onFailure = (res) => {
        console.log('[Login Failure] : '+res);
    };

    //Hata Yaklama BAŞLANGIÇ!
    var array2 = [];
    var rakamSayac = false;
    function signupEmailError() {
        if(state.signupEmail != undefined) {
            if(state.signupEmail.trim()) {
                rakamSayac = true;
                array2 = state.signupEmail.split("");
                array2.forEach(element => {
                    if(element == '@') rakamSayac = false;
                });

                if(rakamSayac == false) errors.signupEmail = "";
                else errors.signupEmail = "E-posta kurallara uymuyor."
            }
            else {
                errors.signupEmail = "E-mail alanını boş bırakılamaz."
            }
        }
    }

    function signupPasswordError() {
        if(state.signupPassword != undefined) {
            if(state.signupPassword.trim()) {
                errors.signupPassword = "";
            }
            else {
                errors.signupPassword = "Şifre alanı boş bırakılamaz."
            }
        }
    }
    //Hata Yakalama SON!

    return(
        <div className="flex mx-auto items-center p-10 h-full  bg-[#f5f5f5] font-Nunito text-sm">
            <div className="relative flex flex-col p-20 outline-formUye rounded-lg max-w-lg mx-auto my-0 w-full  text-[#484848]">
                <div className="hata-gösterme-bölümü"></div>
                
                <form noValidate="" onSubmit={onSuccess}>
                    <div className="text-sm font-extrabold mb-4">E-posta adresi ile Giriş yap</div>
                    <div className="relative group">
                        <input id="signupEmail" name="signupEmail" type="email" value={state.signupEmail} onClick={signupEmailError()} onChange={handleChange} className="w-full rounded-lg px-4 pt-4 pb-2 border-2 mb-5 focus:shadow-bootstrapBlue   peer" required/>
                        <label htmlFor="signupEmail" className="absolute top-0 left-0 h-full px-4 py-3 border border-solid border-transparent  transition-all duration-300 group-focus-within:px-0.5 group-focus-within:py-0.5 group-focus-within:text-xs group-focus-within:text-gray-500 group-focus-within:font-extrabold   peer-valid:p-0.5 peer-valid:text-xs peer-valid:text-gray-500 peer-valid:font-extrabold">E-posta adresi</label>
                        {errors.signupEmail && <p className="font-Nunito text-xs text-red-600  absolute top-[3rem]">{errors.signupEmail}</p>}
                    </div>
                    <div className="relative group">
                        <input id="signupPassword" name="signupPassword" value={state.signupPassword} onClick={signupPasswordError()} onChange={handleChange} className="w-full rounded-lg px-4 pt-4 pb-2 border-2 mb-5 focus:shadow-bootstrapBlue   peer" type={passwordShown ? "text" : "password"} required/>
                        <p onClick={tagglePassword} className="absolute z-10  top-4 right-3">
                            {passwordShown && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20" height="20"><path d="M15 5C6.081703 5 0.32098813 14.21118 0.21679688 14.378906 A 1 1 0 0 0 0 15 A 1 1 0 0 0 0.16210938 15.544922 A 1 1 0 0 0 0.16601562 15.550781C0.18320928 15.586261 5.0188313 25 15 25C24.938822 25 29.767326 15.678741 29.826172 15.564453 A 1 1 0 0 0 29.837891 15.544922 A 1 1 0 0 0 30 15 A 1 1 0 0 0 29.785156 14.380859 A 1 1 0 0 0 29.783203 14.378906C29.679012 14.21118 23.918297 5 15 5 z M 15 8C18.866 8 22 11.134 22 15C22 18.866 18.866 22 15 22C11.134 22 8 18.866 8 15C8 11.134 11.134 8 15 8 z M 15 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 18 A 3 3 0 0 0 18 15 A 3 3 0 0 0 15 12 z" /></svg>}
                            {!passwordShown && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20" height="20"><path d="M1.7070312 0.29296875L0.29296875 1.7070312L28.292969 29.707031L29.707031 28.292969L23.681641 22.267578C27.777456 19.49434 29.799165 15.616636 29.826172 15.564453 A 1 1 0 0 0 30 15 A 1 1 0 0 0 29.783203 14.378906C29.679012 14.21118 23.918297 5 15 5C12.469857 5 10.199331 5.7501922 8.234375 6.8203125L1.7070312 0.29296875 z M 15 8C18.866 8 22 11.134 22 15C22 16.571956 21.470043 18.012848 20.59375 19.179688L17.701172 16.287109C17.889655 15.897819 18 15.462846 18 15C18 13.343 16.657 12 15 12C14.537154 12 14.102181 12.110345 13.712891 12.298828L10.820312 9.40625C11.987152 8.5299565 13.428044 8 15 8 z M 4.9511719 9.0761719C1.9791583 11.576125 0.27498083 14.287031 0.21875 14.376953 A 1 1 0 0 0 0 15 A 1 1 0 0 0 0.16210938 15.544922 A 1 1 0 0 0 0.16601562 15.550781C0.18320928 15.586261 5.0188313 25 15 25C16.85 25 18.520531 24.673484 20.019531 24.146484L17.431641 21.556641C16.672641 21.838641 15.856 22 15 22C11.134 22 8 18.866 8 15C8 14.144 8.1613594 13.327359 8.4433594 12.568359L4.9511719 9.0761719 z"/></svg>}
                        </p>
                        <label htmlFor="signupPassword" className="absolute top-0 left-0 h-full px-4 py-3 border border-solid border-transparent  transition-all duration-300 group-focus-within:px-0.5 group-focus-within:py-0.5 group-focus-within:text-xs group-focus-within:text-gray-500 group-focus-within:font-extrabold   peer-valid:p-0.5 peer-valid:text-xs peer-valid:text-gray-500 peer-valid:font-extrabold">Şifre</label>
                        {errors.signupPassword && <p className="font-Nunito text-xs text-red-600  absolute top-[3rem]">{errors.signupPassword}</p>}        
                    </div>
                    <button className="w-full font-extrabold bg-[#0d6efd] text-white border border-solid border-transparent py-2 px-4 rounded-md  hover:bg-[#0b5ed7] focus:bg-[#0b5ed7] focus:border-[#0a58ca]" type='submit'>Giriş Yap</button>
                </form>

                <div className="my-4 flex items-center text-center before:mr-1 before:flex-1 before:border-b before:border-solid before:border-[#484848]   after:ml-1 after:flex-1 after:border-b after:border-solid after:border-[#484848]">
                    <b className="text-center text-[#484848]">veya</b>
                </div>

                <div className="relative flex flex-row">
                    <GoogleLogin 
                        clientId={googleClientId}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="w-full flex mx-auto justify-center items-center  font-Nunito font-extrabold  rounded-lg bg-blue-100 px-4 py-2 border-2 border-solid border-blue-100 transition duration-300 hover:border-blue-400 hover:bg-blue-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"><g fill="none"><path fill="#4285F4" d="M23.989 12.511c0-1.006-.082-1.74-.259-2.502H12.24v4.542h6.744c-.136 1.129-.87 2.828-2.502 3.97l-.023.153 3.633 2.814.252.026c2.312-2.136 3.645-5.277 3.645-9.003"></path><path fill="#34A853" d="M12.24 24.478c3.304 0 6.078-1.088 8.104-2.964l-3.862-2.992c-1.034.72-2.42 1.224-4.243 1.224-3.236 0-5.983-2.135-6.963-5.086l-.143.012-3.778 2.924-.05.137c2.013 3.998 6.147 6.745 10.934 6.745"></path><path fill="#FBBC05" d="M5.276 14.66c-.258-.762-.408-1.578-.408-2.42 0-.844.15-1.66.395-2.422l-.007-.162-3.825-2.97-.126.059C.476 8.405 0 10.267 0 12.239s.476 3.835 1.305 5.494l3.971-3.073"></path><path fill="#EB4335" d="M12.24 4.732c2.297 0 3.847.993 4.731 1.823l3.455-3.373C18.304 1.21 15.544 0 12.239 0 7.452 0 3.32 2.747 1.305 6.745l3.958 3.073c.993-2.95 3.74-5.086 6.976-5.086"></path></g></svg>
                                Google
                            </button>
                        )}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        buttonText="Login"
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={false}
                    />
                    <FacebookLogin
                        appId={facebokClientId}
                        autoLoad={false}
                        fields="email, name"
                        callback={onSuccess}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="ml-2 w-full flex mx-auto justify-center items-center  font-Nunito font-extrabold  rounded-lg bg-blue-100 px-4 py-2 border-2 border-solid border-blue-100 transition duration-300 hover:border-blue-400 hover:bg-blue-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><linearGradient id="prefix__FacebookCircle" x1="50%" x2="50%" y1="99.999%" y2="0%"><stop offset="0%" stopColor="#1569CB"></stop><stop offset="100%" stopColor="#29AEF3"></stop></linearGradient></defs><g fill="none"><path fill="url(#prefix__FacebookCircle)" d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.855v-8.386H7.079V12h3.046V9.357c0-3.007 1.791-4.67 4.533-4.67 1.313 0 2.687.234 2.687.234v2.954H15.83c-1.491 0-1.956.925-1.956 1.873V12h3.328l-.532 3.47h-2.796v8.385C19.613 22.955 24 17.989 24 12"></path><path fill="#FFF" d="M17.203 12h-3.328V9.748c0-.949.465-1.873 1.956-1.873h1.514V4.921s-1.374-.234-2.687-.234c-2.742 0-4.533 1.662-4.533 4.67V12H7.079v3.47h3.046v8.385c.61.096 1.238.145 1.875.145s1.264-.05 1.875-.145v-8.386h2.796L17.203 12z"></path></g></svg>
                                Facebook
                            </button>
                        )}
                    />
                </div>
            </div>
        </div>
    )
};