import React from 'react';
import Slider from 'infinite-react-carousel';

import MainImageImage4k from 'image/MainImage/4KMainImage.png'
import MainImageImage2k from 'image/MainImage/2KMainImage.png'
import MainImageImagefhd from 'image/MainImage/FHDMainImage.png'
import MainImageImagehd from 'image/MainImage/HDMainImage.png'
import MainImage1 from 'image/MainImage/AF1QipNNF5vEFc5fbBlTh8-Z36hr7rXXd8s9aO2uXME=w1080-h608-p-no.jpg'
import MainImage2 from 'image/MainImage/a08614e3-5799-4434-9fc2-216f7d27e9f3.jpg'

export default function MainImage() {
    const screenSize = window.screen.height;
    var mainImage = null;
    switch(true) {
        case screenSize <= 720:
            mainImage = MainImageImagehd;
            break;
        case screenSize <= 1080:
            mainImage = MainImageImagefhd;
            break;
        case screenSize <= 1440:
            mainImage = MainImageImage2k;
            break;
        default:
            mainImage = MainImageImage4k;
    }

    return(
        <div className="flex justify-center align-middle w-full h-full">
            <Slider dots className="w-full bg-bgBlue hd:block hidden">
                <div className="flex justify-center align-middle">
                    <img className=" fhd:block hd:block hidden   mx-auto" src={mainImage}></img>
                </div>
                <div>
                    <img className="block mx-auto w-1/2" src={MainImage1}></img>
                </div>
                <div>
                    <img className="block m-auto w-1/2" src={MainImage2} alt="Fotoğrafı ilk yüklerken kullanıcıya w-1/2 w-3/4 w-3/5 arasında fotoğrafı göstererek seçim yaptır."></img>
                </div>
                <div>
                    <img className="block m-auto w-3/5" src={MainImage2}></img>
                </div>
                <div>
                    <img className="block m-auto w-3/4" src={MainImage2}></img>
                </div>
            </Slider>
        </div>
    )
};