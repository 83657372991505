import React from 'react';

export default function Zeytin() {
    const [state, setState] = React.useState({
        zeytinYag : false,
        zeytinSiyah : false,
        zeytinYesil : false,

        normalTuz : false,
        azTuz : false,
        yokTuz : false,

        iriZeytin : false,
        normalZeytin : false,
        kucukZeytin : false
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return (
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-green-500 rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">Zeytin Filtrele</div>
                <svg className="fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,224L48,240C96,256,192,288,288,277.3C384,267,480,213,576,192C672,171,768,181,864,160C960,139,1056,85,1152,96C1248,107,1344,181,1392,218.7L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                    <div><h6 className="font-bold selection:bg-transparent">Kategori</h6>
                        <div className="flex flex-col ml-6">
                            <label>
                                <input type="checkbox" name="zeytinYag" id="zeytinYag" checked={state.zeytinYag} onChange={handleChange} />
                                <label htmlFor="zeytinYag" className="ml-2 selection:bg-transparent">Zeytin Yağı</label>
                            </label>
                            <label>
                                <input type="checkbox" name="zeytinSiyah" id="zeytinSiyah" checked={state.zeytinSiyah} onChange={handleChange} />
                                <label htmlFor="zeytinSiyah" className="ml-2 selection:bg-transparent">Siyah Zeytin</label>
                            </label>
                            <label>
                                <input type="checkbox" name="zeytinYesil" id="zeytinYesil" checked={state.zeytinYesil} onChange={handleChange} />
                                <label htmlFor="zeytinYesil" className="ml-2 selection:bg-transparent">Yeşil Zeytin</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="my-5 w-3/4">
                        <div><h6 className="font-bold selection:bg-transparent">Tuz Miktarı</h6>
                            <div className="grid col-auto ml-6">
                                <label>
                                    <input type="checkbox" name="normalTuz" id="normalTuz" checked={state.normalTuz} onChange={handleChange} />
                                    <label htmlFor="normalTuz" className="ml-2 selection:bg-transparent">Normal Tuzlu</label>
                                </label>
                                <label>
                                    <input type="checkbox" name="azTuz" id="azTuz" checked={state.azTuz} onChange={handleChange} />
                                    <label htmlFor="azTuz" className="ml-2 selection:bg-transparent">Az Tuzlu</label>
                                </label>
                                <label>
                                    <input type="checkbox" name="yokTuz" id="yokTuz" checked={state.yokTuz} onChange={handleChange} />
                                    <label htmlFor="yokTuz" className="ml-2 selection:bg-transparent">Tuzsuz</label>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="my-5 w-3/4">
                        <div><h6 className="font-bold selection:bg-transparent">İrilik Oranı</h6>
                            <div className="grid col-auto ml-6">
                                <label>
                                    <input type="checkbox" name="iriZeytin" id="iriZeytin" checked={state.iriZeytin} onChange={handleChange} />
                                    <label htmlFor="iriZeytin" className="ml-2 selection:bg-transparent">Büyük</label>
                                </label>
                                <label>
                                    <input type="checkbox" name="normalZeytin" id="normalZeytin" checked={state.normalZeytin} onChange={handleChange} />
                                    <label htmlFor="normalZeytin" className="ml-2 selection:bg-transparent">Normal</label>
                                </label>
                                <label>
                                    <input type="checkbox" name="kucukZeytin" id="kucukZeytin" checked={state.kucukZeytin} onChange={handleChange} />
                                    <label htmlFor="kucukZeytin" className="ml-2 selection:bg-transparent">Küçük</label>
                                </label>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
};