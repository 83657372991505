import BrandWhite from 'image/KayaWhite.png'

import { Link } from 'react-router-dom';

export default function Footer() {
    return(
        <div className="bg-upFooter">
            <div className="hidden md:flex p-8 pl-24 ">
                <Link to="/"><img src={BrandWhite} alt="KAYA"></img></Link>
                <div className="flex mx-auto flex-col">
                    <table>
                        <thead>
                            <tr><td className="text-white text-lg font-Fira text-center">İLETİŞİM<hr className="border w-48"></hr></td></tr>
                        </thead>
                        <tbody>
                            <tr><td className="text-gray-300"><a href="#BizeUlasin">Bize Ulaşın</a></td></tr>
                            <tr><td className="text-gray-300"><a href="https://www.instagram.com/kaya_suturunleri/" target="_blank">INSTAGRAM: @kaya_suturunleri</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex mx-auto flex-col">
                    <table>
                        <thead>
                            <tr><td className="text-white text-lg font-Fira text-center">KATEGORİLER<hr className="border w-48"></hr></td></tr>
                        </thead>
                        <tbody>
                            <tr><td className="text-gray-300"><a href="#peynir">Peynir çeşitleri</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#zeytin&yag">Zeytin ve Zeytin Yağları</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#marmelat&recel">Marmelat ve Reçeller</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#bal">Bal Çeşitleri</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#kahvaltılık">Kahvaltılık Ürünler</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#sarkuteri">Et ve Şarküteri</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#kurugida">Kuru Gıda ve Baharatlar</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex mx-auto flex-col">
                    <table>
                        <thead>
                            <tr><td className="text-white text-lg font-Fira text-center">SATIŞ YERLERİMİZ<hr className="border w-48"></hr></td></tr>
                        </thead>
                        <tbody>
                            <tr><td className="text-gray-300"><a href="https://goo.gl/maps/Vw5n6iv8JsGNSQFcA" target="_blank">Kuşadası Satış Mağazamız</a></td></tr>
                            <tr><td><br></br></td></tr>
                            <tr><td className="text-gray-300"><a href="https://goo.gl/maps/kqPSrEFjAtTtuN8x9" target="_blank">Davutlar Halk Pazarı</a></td></tr>
                            <tr><td className="text-gray-300"><a href="https://goo.gl/maps/fHtQuErok3J67qxk6" target="_blank">Güzelçamlı Halk Pazarı</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex mx-auto flex-col">
                    <table>
                        <thead>
                            <tr><td className="text-white text-lg font-Fira text-center">HAKKIMIZDA<hr className="border w-48"></hr></td></tr>
                        </thead>
                        <tbody>
                            <tr><td className="text-gray-300"><a href="#Vizyonumuz">Vizyonumuz</a></td></tr>
                            <tr><td className="text-gray-300"><a href="#Misyonumuz">Misyonumuz</a></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="Mobile" className="block md:hidden pb-10">
                <div className="flex justify-center  p-5">
                    <Link to="/" className="block mr-auto ml-auto w-1/3"><img className="block mr-auto ml-auto" src={BrandWhite} alt="KAYA"></img></Link>
                </div>
                <div className="flex justify-start">
                    <table className="block mt-3 p-5 bg-gray-600 rounded-r-3xl">
                        <thead>
                            <tr><td className="text-white text-lg font-Fira text-center">İLETİŞİM<hr className="border"></hr></td></tr>
                        </thead>
                        <tbody>
                            <tr><td className="text-gray-300"><a href="#BizeUlasin">Bize Ulaşın</a></td></tr>
                            <tr><td className="text-gray-300"><a href="https://www.instagram.com/kaya_suturunleri/" target="_blank">INSTAGRAM: @kaya_suturunleri</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end">
                    <table className="block mt-3 p-5 bg-gray-600 rounded-l-3xl">
                        <thead>
                        <tr><td className="text-white text-lg font-Fira text-center">KATEGORİLER<hr className="border"></hr></td></tr>
                        </thead>
                        <tbody>
                        <tr><td className="text-gray-300"><a href="#peynir">Peynir çeşitleri</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#zeytin&yag">Zeytin ve Zeytin Yağları</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#marmelat&recel">Marmelat ve Reçeller</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#bal">Bal Çeşitleri</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#kahvaltılık">Kahvaltılık Ürünler</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#sarkuteri">Et ve Şarküteri</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#kurugida">Kuru Gıda ve Baharatlar</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-start">
                    <table className="block mt-3 p-5 bg-gray-600 rounded-r-3xl">
                        <thead>
                        <tr><td className="text-white text-lg font-Fira text-center">SATIŞ YERLERİMİZ<hr className="border"></hr></td></tr>
                        </thead>
                        <tbody>
                        <tr><td className="text-gray-300"><a href="https://goo.gl/maps/Vw5n6iv8JsGNSQFcA" target="_blank">Kuşadası Satış Mağazamız</a></td></tr>
                        <tr><td className="text-gray-300"><a href="https://goo.gl/maps/kqPSrEFjAtTtuN8x9" target="_blank">Davutlar Halk Pazarı</a></td></tr>
                        <tr><td className="text-gray-300"><a href="https://goo.gl/maps/fHtQuErok3J67qxk6" target="_blank">Güzelçamlı Halk Pazarı</a></td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end">
                    <table className="block mt-3 p-5 bg-gray-600 rounded-l-3xl">
                        <thead>
                        <tr><td className="text-white text-lg font-Fira text-center">HAKKIMIZDA<hr className="border"></hr></td></tr>
                        </thead>
                        <tbody>
                        <tr><td className="text-gray-300"><a href="#Vizyonumuz">Vizyonumuz</a></td></tr>
                        <tr><td className="text-gray-300"><a href="#Misyonumuz">Misyonumuz</a></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};