import {Route, Switch, Link} from 'react-router-dom';
import Peynir from './Peynir';
import Zeytin from './Zeytin';
import RecelveMarmelat from './Recelvemarmelat';
import Bal from './Bal';
import Tahinpekmezvehelva from './Tahinpekmezvehelva';
import Kahvaltilikurunler from './Kahvaltilikurunler';
import Sarkuteri from './Sarkuteri';
import Kurugidavebaharatlar from './Kurugidavebaharatlar';

function importAll(r) {
    return r.keys().map(r);
}
  
const products = importAll(require.context('image/Products/', false, /\.(png|jpe?g|svg|jpg)$/));

export default function Product() {
    return (
        <div className="py-6  flex mx-auto justify-center container   bg-white">
            <Switch>
                <Route path="/Urunlerimiz/Peynir" component={Peynir}></Route>
                <Route path="/Urunlerimiz/Zeytin" component={Zeytin}></Route>
                <Route path="/Urunlerimiz/Recel" component={RecelveMarmelat}></Route>
                <Route path="/Urunlerimiz/Bal" component={Bal}></Route>
                <Route path="/Urunlerimiz/Helva" component={Tahinpekmezvehelva}></Route>
                <Route path="/Urunlerimiz/Kahvaltilik" component={Kahvaltilikurunler}></Route>
                <Route path="/Urunlerimiz/Sarkuteri" component={Sarkuteri}></Route>
                <Route path="/Urunlerimiz/Baharat" component={Kurugidavebaharatlar}></Route>
                <Route path="/Urunlerimiz"></Route>
            </Switch>
            <div itemID="productSide" className=" grid grid-cols-4 content-start  w-5/6 mx-2  bg-bgBlue rounded-lg flex-nowrap">
                <Link to="/" itemID="product" className="fhd:m-4 hd:m-1 m-0 shadow-sm bg-white rounded-lg  hover:contrast-125">
                    <div className="flex flex-row justify-center align-middle py-2 w-full h-  font-semibold text-md text-white bg-yellow-600 rounded-t-lg">Az Tuzlu Bergama</div>
                    <div className="flex flex-row justify-center align-middle"><img className="" src={products[0].default} alt="" /></div>
                    <div className="flex flex-row justify-center align-middle"><div className="flex mx-auto justify-center p-2 w-full align-middle  bg-yellow-600 text-white text-sm font-Rubik rounded-b-lg">Ürünü İncele</div></div>
                </Link>
                <Link to="/" itemID="product" className="fhd:m-4 hd:m-1 m-0 shadow-sm bg-white rounded-lg  hover:contrast-125">
                    <div className="flex flex-row justify-center align-middle py-2 w-full  font-semibold text-md text-white bg-yellow-600 rounded-t-lg">Keçi Koyun Karışık Ezine</div>
                    <div className="flex flex-row justify-center align-middle"><img className="" src={products[1].default} alt="" /></div>
                    <div className="flex flex-row justify-center align-middle"><div className="flex mx-auto justify-center p-2 w-full align-middle  bg-yellow-600 text-white text-sm font-Rubik rounded-b-lg">Ürünü İncele</div></div>
                </Link>
                <Link to="/" itemID="product" className="fhd:m-4 hd:m-1 m-0 shadow-sm bg-white rounded-lg  hover:contrast-125">
                    <div className="flex flex-row justify-center align-middle py-2 w-full  font-semibold text-md text-white bg-yellow-600 rounded-t-lg">Deri Erzincan Tulum</div>
                    <div className="flex flex-row justify-center align-middle"><img className="" src={products[3].default} alt="" /></div>
                    <div className="flex flex-row justify-center align-middle"><div className="flex mx-auto justify-center p-2 w-full align-middle  bg-yellow-600 text-white text-sm font-Rubik rounded-b-lg">Ürünü İncele</div></div>
                </Link>
                <Link to="/" itemID="product" className="fhd:m-4 hd:m-1 m-0 shadow-sm bg-white rounded-lg  hover:contrast-125">
                    <div className="flex flex-row justify-center align-middle py-2 w-full  font-semibold text-md text-white bg-yellow-600 rounded-t-lg">Az Tuzlu Köy Peyniri</div>
                    <div className="flex flex-row justify-center align-middle"><img className="" src={products[4].default} alt="" /></div>
                    <div className="flex flex-row justify-center align-middle"><div className="flex mx-auto justify-center p-2 w-full align-middle  bg-yellow-600 text-white text-sm font-Rubik rounded-b-lg">Ürünü İncele</div></div>
                </Link>
                <Link to="/" itemID="product" className="fhd:m-4 hd:m-1 m-0 shadow-sm bg-white rounded-lg  hover:contrast-125">
                    <div className="flex flex-row justify-center align-middle py-2 w-full  font-semibold text-md text-white bg-yellow-600 rounded-t-lg">Az Tuzlu Çeçil Peyniri</div>
                    <div className="flex flex-row justify-center align-middle"><img className="" src={products[6].default} alt="" /></div>
                    <div className="flex flex-row justify-center align-middle"><div className="flex mx-auto justify-center p-2 w-full align-middle  bg-yellow-600 text-white text-sm font-Rubik rounded-b-lg">Ürünü İncele</div></div>
                </Link>
            </div>
        </div>
    )
};