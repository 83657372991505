import React from 'react';

export default function Kahvaltilikurunler() {
    const [state, setState] = React.useState({
        
    });

    function handleChange(evt) {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setState({
            ...state,
            [evt.target.name] : value
        });
    }

    return(
        <div itemID="productFilterSide" className="w-1/6 mx-3  bg-bgBlue rounded-lg">
            <div className="flex flex-col items-center">
                <div className="flex justify-center w-full text-center   bg-blue-400 rounded-t-lg pt-4   font-Lobster text-bgBlue text-2xl">Kahvaltılık Ürünler Filtrele</div>
                <svg className="text-blue-400 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fillOpacity="1" d="M0,96L16,85.3C32,75,64,53,96,42.7C128,32,160,32,192,42.7C224,53,256,75,288,69.3C320,64,352,32,384,58.7C416,85,448,171,480,186.7C512,203,544,149,576,138.7C608,128,640,160,672,192C704,224,736,256,768,240C800,224,832,160,864,149.3C896,139,928,181,960,186.7C992,192,1024,160,1056,133.3C1088,107,1120,85,1152,74.7C1184,64,1216,64,1248,69.3C1280,75,1312,85,1344,85.3C1376,85,1408,75,1424,69.3L1440,64L1440,0L1424,0C1408,0,1376,0,1344,0C1312,0,1280,0,1248,0C1216,0,1184,0,1152,0C1120,0,1088,0,1056,0C1024,0,992,0,960,0C928,0,896,0,864,0C832,0,800,0,768,0C736,0,704,0,672,0C640,0,608,0,576,0C544,0,512,0,480,0C448,0,416,0,384,0C352,0,320,0,288,0C256,0,224,0,192,0C160,0,128,0,96,0C64,0,32,0,16,0L0,0Z"></path></svg>
                
                <div className="my-5 w-3/4">
                
                </div>
            </div>
        </div>
    )
};