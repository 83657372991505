import React from "react";
import {Switch, Route, Link} from 'react-router-dom';

import KullaniciBilgileri from "./Component/KullaniciBilgileri";
import Adresler from "./Component/Adresler";
import Siparisler from "./Component/Siparisler";
import Begenilenler from "./Component/Begenilenler";
import Kuponlar from "./Component/Kuponlar";
import AccountExit from "./Component/AccountExit";

export default function Account() {
    const MenuButtonChangeColor = (arg) => {
        const allButtons = document.getElementsByClassName("BUTTON");
        
        for (let index = 0; index < allButtons.length; index++) {
            if(arg === index) {
                allButtons[index].classList.replace("bg-white", "bg-bgBlueDarken");
                allButtons[index].classList.replace("hover:bg-bgBlue", "hover:bg-bgBlueDarken");
            }
            else {
                allButtons[index].classList.replace("bg-bgBlueDarken", "bg-white");
                allButtons[index].classList.replace("hover:bg-bgBlueDarken", "hover:bg-bgBlue")
            }
        }
    }
    return(
        <div className="flex mt-10">
            <div className="flex w-1/6 mx-auto rounded-lg">
                <div className="flex flex-col items-center">
                    <div className="flex justify-center w-full text-center  bg-yellow-600 text-white  rounded-t-lg pt-4 font-Caveat text-4xl">İsim Soyisim</div>
                    <svg className="text-yellow-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="" fill-opacity="1" d="M0,32L80,64C160,96,320,160,480,192C640,224,800,224,960,192C1120,160,1280,96,1360,64L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
                    <div className="w-full">
                        <Link className="BUTTON flex items-center bg-bgBlueDarken p-3 outline-none hover:bg-bgBlueDarken rounded-lg" onClick={MenuButtonChangeColor.bind(this, 0)} to="/Hesabim">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M22.998047 4C17.484047 4 12.998047 8.486 12.998047 14C12.998047 19.514 17.484047 24 22.998047 24C28.512047 24 32.998047 19.514 32.998047 14C32.998047 8.486 28.512047 4 22.998047 4 z M 37 23C36.69425 23 36.387719 23.126906 36.199219 23.378906C33.548219 26.912906 29.042 26.978516 29 26.978516C28.448 26.978516 28 27.426516 28 27.978516L28 34C28 43.669 36.670813 45.948703 36.757812 45.970703C36.837813 45.990703 36.918 46 37 46C37.082 46 37.162187 45.990703 37.242188 45.970703C37.329188 45.948703 46 43.669 46 34L46 27.978516C46 27.428516 45.553906 26.981516 45.003906 26.978516C44.958906 26.978516 40.451781 26.912906 37.800781 23.378906C37.612281 23.126906 37.30575 23 37 23 z M 10.978516 27.998047C8.7845156 27.998047 6.9980469 29.784516 6.9980469 31.978516L6.9980469 33.498047C6.9980469 36.810047 8.8846406 39.6745 12.306641 41.5625C15.154641 43.1335 18.951047 43.998047 22.998047 43.998047L23.001953 43.998047C25.172953 43.998047 27.266547 43.742531 29.185547 43.269531C27.410547 41.158531 26 38.17 26 34L26 27.998047L10.978516 27.998047 z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Kullanıcı Bilgilerim</div>
                        </Link>
                        <Link className="BUTTON flex items-center bg-white p-3 outline-none hover:bg-bgBlue rounded-lg" onClick={MenuButtonChangeColor.bind(this, 1)} to="/Hesabim/Adreslerim">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M24 5C17.383 5 12 10.383 12 17C12 21.805 17.940422 30.180141 21.482422 34.744141C22.090422 35.528141 23.009 35.978516 24 35.978516C24.991 35.978516 25.909578 35.528141 26.517578 34.744141C30.059578 30.180141 36 21.805 36 17C36 10.383 30.617 5 24 5 z M 24 14C25.657 14 27 15.343 27 17C27 18.657 25.657 20 24 20C22.343 20 21 18.657 21 17C21 15.343 22.343 14 24 14 z M 11.724609 30C10.342609 30 9.0853906 30.816125 8.5253906 32.078125L6.3046875 37.078125C5.8216875 38.165125 5.9184062 39.40925 6.5664062 40.40625C7.2154063 41.40425 8.3129531 42 9.5019531 42L38.496094 42C39.685094 42 40.783641 41.40425 41.431641 40.40625C42.080641 39.40925 42.178313 38.166125 41.695312 37.078125L39.474609 32.078125C38.913609 30.816125 37.658391 30 36.275391 30L33.019531 30C32.382531 30.965 31.691312 31.964 30.945312 33L36.275391 33C36.472391 33 36.652422 33.115875 36.732422 33.296875L38.953125 38.296875C39.049125 38.512875 38.972016 38.688437 38.916016 38.773438C38.860016 38.858438 38.734047 39 38.498047 39L9.5019531 39C9.2659531 39 9.1370312 38.856484 9.0820312 38.771484C9.0270313 38.686484 8.9499219 38.512875 9.0449219 38.296875L11.267578 33.296875C11.347578 33.116875 11.527609 33 11.724609 33L17.052734 33C16.305734 31.964 15.616469 30.965 14.980469 30L11.724609 30 z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Adreslerim</div>
                        </Link>
                        <Link className="BUTTON flex items-center bg-white p-3 outline-none hover:bg-bgBlue rounded-lg" onClick={MenuButtonChangeColor.bind(this, 2)} to="/Hesabim/Begendiklerim">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M14 5C10.795 5 7.781625 6.248625 5.515625 8.515625C3.249625 10.782625 2 13.794 2 17C2 20.206 3.248625 23.218375 5.515625 25.484375C9.576625 29.545375 22.821125 42.444219 22.953125 42.574219C23.245125 42.858219 23.622 43 24 43C24.378 43 24.754875 42.857219 25.046875 42.574219C25.085875 42.536219 26.228781 41.423578 27.925781 39.767578C26.567781 38.508578 25.250234 37.270766 24.490234 36.509766C22.884234 34.904766 22 32.77 22 30.5C22 28.23 22.884234 26.095281 24.490234 24.488281C26.096234 22.883281 28.23 22 30.5 22C32.112 22 33.662 22.448156 35 23.285156C36.338 22.448156 37.888 22 39.5 22C41.179 22 42.780391 22.486719 44.150391 23.386719C45.348391 21.490719 46 19.297 46 17C46 13.794 44.751375 10.781625 42.484375 8.515625C40.217375 6.249625 37.205 5 34 5C30.795 5 27.781625 6.249625 25.515625 8.515625C24.941625 9.088625 24.429 9.7679688 24 10.417969C23.571 9.7669688 23.058375 9.0866719 22.484375 8.5136719C20.218375 6.2476719 17.205 5 14 5 z M 30.5 24C28.764 24 27.132297 24.675344 25.904297 25.902344C24.677297 27.130344 24 28.764 24 30.5C24 32.236 24.677297 33.868703 25.904297 35.095703C27.860297 37.051703 33.742188 42.385328 33.992188 42.611328C34.277187 42.871328 34.64 43 35 43C35.36 43 35.722812 42.870328 36.007812 42.611328C36.257812 42.385328 42.139703 37.053656 44.095703 35.097656C45.322703 33.869656 46 32.236 46 30.5C46 28.764 45.323703 27.130344 44.095703 25.902344C42.867703 24.675344 41.236 24 39.5 24C37.809 24 36.216 24.642547 35 25.810547C33.784 24.642547 32.191 24 30.5 24 z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Beğendiklerim</div> 
                        </Link>
                        <Link className="BUTTON flex items-center bg-white p-3 outline-none hover:bg-bgBlue rounded-lg" onClick={MenuButtonChangeColor.bind(this, 3)} to="/Hesabim/Siparislerim">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M4.5 7 A 1.50015 1.50015 0 1 0 4.5 10C6.4500619 10 8 11.549938 8 13.5L8 29.5C8 34.728774 12.271226 39 17.5 39L18.501953 39 A 2.5 2.5 0 0 0 20.5 43 A 2.5 2.5 0 0 0 22.498047 39L35.501953 39 A 2.5 2.5 0 0 0 37.5 43 A 2.5 2.5 0 0 0 39.498047 39L43.5 39 A 1.50015 1.50015 0 1 0 43.5 36L17.5 36C13.892774 36 11 33.107226 11 29.5L11 13.5C11 9.9280619 8.0719381 7 4.5 7 z M 15.5 10C14.672 10 14 10.671 14 11.5L14 28.5C14 30.981 16.019 33 18.5 33L37.5 33C39.981 33 42 30.981 42 28.5L42 11.5C42 10.671 41.328 10 40.5 10L15.5 10 z M 24.5 15L31.5 15C32.328 15 33 15.671 33 16.5C33 17.329 32.328 18 31.5 18L24.5 18C23.672 18 23 17.329 23 16.5C23 15.671 23.672 15 24.5 15 z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Siparişlerim</div>
                        </Link>
                        <Link className="BUTTON flex items-center bg-white p-3 outline-none hover:bg-bgBlue rounded-lg" onClick={MenuButtonChangeColor.bind(this, 4)} to="/Hesabim/Kuponlarim">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M43.6,18.53c0.79-0.05,1.4-0.71,1.4-1.5V13.5c0-1.93-1.57-3.5-3.5-3.5H33v2c0,0.83-0.67,1.5-1.5,1.5S30,12.83,30,12v-2H6.5 C4.57,10,3,11.57,3,13.5v3.53c0,0.79,0.61,1.45,1.4,1.5c2.86,0.18,5.1,2.59,5.1,5.47s-2.24,5.29-5.1,5.47 C3.61,29.52,3,30.18,3,30.97v3.53C3,36.43,4.57,38,6.5,38H30v-2c0-0.83,0.67-1.5,1.5-1.5S33,35.17,33,36v2h8.5 c1.93,0,3.5-1.57,3.5-3.5v-3.53c0-0.79-0.61-1.45-1.4-1.5c-2.86-0.18-5.1-2.59-5.1-5.47S40.74,18.71,43.6,18.53z M33,30 c0,0.83-0.67,1.5-1.5,1.5S30,30.83,30,30v-3c0-0.83,0.67-1.5,1.5-1.5S33,26.17,33,27V30z M33,21c0,0.83-0.67,1.5-1.5,1.5 S30,21.83,30,21v-3c0-0.83,0.67-1.5,1.5-1.5S33,17.17,33,18V21z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Kuponlarım</div>
                        </Link>
                        <div className="flex items-center my-3 outline-none"></div>
                        <Link className="BUTTON flex items-center bg-white p-3 outline-none hover:bg-bgBlue rounded-lg" onClick={MenuButtonChangeColor.bind(this, 5)} to="/Hesabim/Exit">
                            <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24"><path d="M35.5,6h-23C10.567,6,9,7.567,9,9.5v22c0-0.829,0.672-1.5,1.5-1.5h5.515l1.377-1.083c0.485,1.167,1.395,2.133,2.6,2.661l0.163,0.084l-2.776,1.053C17.123,32.9,16.815,33,16.5,33h-6C9.672,33,9,32.329,9,31.5v9c0,0.828,0.672,1.5,1.5,1.5h27c0.828,0,1.5-0.672,1.5-1.5v-31C39,7.567,37.433,6,35.5,6z M28.999,10C30.658,10,32,11.344,32,12.999C32,14.656,30.658,16,28.999,16C27.343,16,26,14.656,26,12.999C26,11.344,27.343,10,28.999,10z M35.5,25.799h-6c-0.491,0-0.951-0.24-1.231-0.644l-2.016-2.899l-1.209,4.894l3.418,3.201c0.281,0.235,0.471,0.565,0.524,0.936l1,7c0.117,0.82-0.453,1.58-1.273,1.697C28.641,39.995,28.568,40,28.498,40c-0.734,0-1.376-0.54-1.483-1.288l-0.89-6.232l-5.281-2.712C19.762,29.315,19,28.246,19,26.999c0-0.247,0.038-0.483,0.095-0.713L20.877,19h-2.781l-4.7,3.502c-0.27,0.201-0.584,0.297-0.896,0.297c-0.457,0-0.909-0.208-1.204-0.604c-0.495-0.664-0.357-1.604,0.307-2.099l5.1-3.799C16.962,16.104,17.276,16,17.6,16c0,0,6.31,0,6.399,0c1.102,0,2.054,0.599,2.576,1.482c0.02,0.025,0.043,0.062,0.061,0.088l3.648,5.229H35.5c0.828,0,1.5,0.671,1.5,1.5S36.328,25.799,35.5,25.799z" /></svg></div>
                            <div className="mx-4 font-Nunito text-xl">Çıkış Yap</div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex w-3/6 mx-auto bg-bgBlue rounded-lg">
                <Switch>
                    <Route path="/Hesabim/Exit" component={AccountExit}></Route>
                    <Route path="/Hesabim/Kuponlarim" component={Kuponlar}></Route>
                    <Route path="/Hesabim/Siparislerim" component={Siparisler}></Route>
                    <Route path="/Hesabim/Begendiklerim" component={Begenilenler}></Route>
                    <Route path="/Hesabim/Adreslerim" component={Adresler}></Route>
                    <Route path="/Hesabim" component={KullaniciBilgileri}></Route> 
                    {/**Hesabım sayfasına ilk girildiğinde aslında kullanıcı bilgileri açılmalıdır.
                     * Bunun için ilk gösterilen sayfa bu sayfa olur.
                     */}
                </Switch>
            </div>
        </div>
    )
}