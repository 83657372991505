function importAll(r) {
    return r.keys().map(r);
}

const product = importAll(require.context('image/Products/', false, /\.(png|jpe?g|svg|jpg)$/));

export default function Favorites() {
    return(
        <div className="py-5 md:py-12 bg-bgBlue">
            <div className="container font-Fira tracking-tight text-xl md:text-3xl">
                Sizin İçin Seçtiklerimiz
            </div>
            <div className="md:m-8">
                <div className="container grid grid-cols-2 md:grid-cols-4">
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200">
                        <div id="Product-Header">
                            <a href="#Urun1"><img className="rounded-lg" src={product[0].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün1
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200">
                        <div id="Product-Header">
                            <a href="#Urun2"><img className="rounded-lg" src={product[1].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün2
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200">
                        <div id="Product-Header">
                            <a href="#Urun3"><img className="rounded-lg" src={product[2].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün3
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200">
                        <div id="Product-Header">
                            <a href="#Urun4"><img className="rounded-lg" src={product[3].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün4
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200 ">
                        <div id="Product-Header">
                            <a href="#Urun5"><img className="rounded-lg" src={product[4].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün5
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200 ">
                        <div id="Product-Header">
                            <a href="#Urun6"><img className="rounded-lg" src={product[5].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün6
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200 ">
                        <div id="Product-Header">
                            <a href="#Urun7"><img className="rounded-lg" src={product[6].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün7
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="flex m-2 md:mx-3.5 md:my-7 flex-col justify-center align-middle  bg-white text-center p-2 md:p-5 relative rounded-xl shadow-md  filter hover:contrast-125 hover:bg-gradient-to-b from-transparent to-red-200 ">
                        <div id="Product-Header">
                            <a href="#Urun8"><img className="rounded-lg" src={product[7].default}></img></a>
                        </div>
                        <div id="Product-Body" className="mt-1 md:mt-3">
                            Ürün8
                        </div>
                        <div id="Product-Footer">
                            <div className="hidden mt-3 md:inline-flex items-center relative text-center">
                                <button id="negative" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-2">-</button>
                                <input className="bg-productNumberBgColor focus:outline-none text-center w-40 h-10 rounded-full" type="text" min="1" defaultValue="1" size="4"></input>
                                <button id="positive" className="flex justify-center align-middle absolute w-8 h-8 text-base bg-white group-hover:outline-orange_md rounded-full leading-8 text-center left-auto right-2">+</button>
                            </div>
                            <button className="flex mx-auto align-middle bg-yellow-600 text-white text-xs font-Rubik rounded-full py-2 px-8 md:px-4 mt-3  group-hover:px-12 duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M11.984375 0.98632812 A 1.0001 1.0001 0 0 0 11 2L11 4L9 4 A 1.0001 1.0001 0 1 0 9 6L11 6L11 8 A 1.0001 1.0001 0 1 0 13 8L13 6L15 6 A 1.0001 1.0001 0 1 0 15 4L13 4L13 2 A 1.0001 1.0001 0 0 0 11.984375 0.98632812 z M 3.0722656 2.0039062L2 2.0136719 A 1.0001 1.0001 0 1 0 2.0175781 4.0136719L3.0839844 4.0039062L3.0859375 4.0039062L6.3476562 11.833984 A 1.0001 1.0001 0 0 0 6.3359375 11.978516L5.1816406 13.822266C4.3432895 15.161013 5.3626785 17 6.9414062 17L18 17 A 1.0001 1.0001 0 1 0 18 15L6.9414062 15C6.8301342 15 6.8173041 14.978071 6.8769531 14.882812L8.0527344 13L15.521484 13C16.246284 13 16.917475 12.604805 17.269531 11.970703L21.382812 4.5664062 A 1.0001 1.0001 0 0 0 20.507812 3.0683594 A 1.0001 1.0001 0 0 0 19.634766 3.5957031L15.521484 11L8.1660156 11L4.9316406 3.2324219C4.6203684 2.4861666 3.8821872 1.9999163 3.0742188 2.0039062L3.0722656 2.0039062 z M 7 18 A 2 2 0 0 0 5 20 A 2 2 0 0 0 7 22 A 2 2 0 0 0 9 20 A 2 2 0 0 0 7 18 z M 17 18 A 2 2 0 0 0 15 20 A 2 2 0 0 0 17 22 A 2 2 0 0 0 19 20 A 2 2 0 0 0 17 18 z" fill="#FFFFFF" />
                                </svg>
                                <div className="hidden md:block px-1">
                                    Sepete Ekle
                                </div>
                                <div className="block md:hidden">
                                    Ekle
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};