import { Link } from "react-router-dom";

export default function MainCategories() {  
    return(
        <div className="lg:container md:flex md:mx-auto bg-bgBlue md:bg-white">
            <div className="flex flex-nowrap overflow-y-hidden justify-between p-0.5 mx-auto md:py-2 lg:py-5">
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Peynir">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 48 48">
                            <path fill="#FFE57F" d="M38,21c0-2.17,1.73-3.926,3.885-3.988c-0.127-4.226-0.315-7.924-0.315-7.924c-16.076,1.325-32.276,4.611-32.276,4.611c-2.151,0.426-2.582,0.852-3.012,2.553c0,0-0.861,3.403,0.43,10.636S12.749,37.1,14.888,37.1c0,0,17.515,1.649,26.682,0.482c0,0,0.362-6.85,0.422-12.583C39.786,24.994,38,23.206,38,21z" />
                            <path fill="#D46E5F" d="M15.075,35.153l-0.094-0.009h-0.094c-0.994,0-5.079-2.148-6.23-8.597c-1.18-6.613-0.462-9.807-0.458-9.823c0.15-0.595,0.237-0.744,0.24-0.754c0.046-0.034,0.274-0.164,1.251-0.356c0.153-0.031,14.905-3.004,29.999-4.399l1.973-0.158c-0.054-1.209-0.093-1.969-0.093-1.969c-16.076,1.325-32.276,4.611-32.276,4.611c-2.151,0.426-2.582,0.852-3.012,2.553c0,0-0.861,3.403,0.43,10.636S12.749,37.1,14.888,37.1c0,0,9.56,0.9,18.25,0.9c3.072,0,6.037-0.113,8.432-0.418c0,0,0.041-0.786,0.099-2.047c-2.4,0.309-6.404,0.51-8.53,0.51C24.646,36.045,15.17,35.162,15.075,35.153z" />
                            <path fill="#8D270D" d="M6.13,17.195c0.073-0.629,0.151-0.943,0.151-0.943c0.43-1.701,0.86-2.127,3.012-2.553c0,0,16.2-3.286,32.276-4.611c0,0-13.456-0.688-16.38-0.974s-5.479,0-6.919,0.448c-1.441,0.447-6.138,2.402-6.138,2.402C5.677,13.457,6.129,17.195,6.13,17.195L6.13,17.195z" />
                            <path fill="#FFC400" d="M22 18A4 4 0 1 0 22 26 4 4 0 1 0 22 18zM33 14A2 2 0 1 0 33 18 2 2 0 1 0 33 14zM16.5 27A2.5 2.5 0 1 0 16.5 32 2.5 2.5 0 1 0 16.5 27zM12.5 20A1.5 1.5 0 1 0 12.5 23 1.5 1.5 0 1 0 12.5 20zM33 25A3 3 0 1 0 33 31 3 3 0 1 0 33 25z" />
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Peynir Çeşitleri
                    </div>
                </Link> 
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Zeytin">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="72" height="72">
                            <path fill="#827717" d="M33,21l-2,9l2,10v4c0,1.66-1.34,3-3,3H18c-1.66,0-3-1.34-3-3v-4l2-10l-2-9v-3.88 c0-1.09,0.25-2.16,0.74-3.12L24,13l8.26,1c0.49,0.96,0.74,2.03,0.74,3.12V21z" />
                            <rect width="18" height="19" x="15" y="21" fill="#f0f4c3" />
                            <path fill="#7cb342" d="M23.067,32.778l-0.254,0.098l0.074-0.262c0.413-1.457,1.16-2.798,2.181-3.916l0,0 c1.021-1.118,2.289-1.984,3.702-2.527l0.254-0.098l-0.074,0.262c-0.413,1.457-1.16,2.798-2.181,3.916l0,0 C25.748,31.369,24.481,32.234,23.067,32.778z" />
                            <path fill="#7cb342" d="M22.88,32.527l-0.194,0.191l-0.037-0.269c-0.203-1.501-0.055-3.028,0.434-4.461l0,0 c0.489-1.433,1.305-2.733,2.384-3.796L25.662,24l0.037,0.269c0.203,1.501,0.055,3.028-0.434,4.461l0,0 C24.775,30.164,23.958,31.464,22.88,32.527z" />
                            <path fill="#7cb342" d="M31.822,32.943l0.216,0.165l-0.261,0.074c-1.457,0.414-2.99,0.483-4.478,0.202l0,0 c-1.488-0.281-2.89-0.905-4.095-1.822l-0.216-0.165l0.261-0.074c1.457-0.414,2.99-0.483,4.478-0.202l0,0 C29.215,31.403,30.617,32.026,31.822,32.943z" />
                            <ellipse cx="20.536" cy="30" fill="#455a64" rx="3.522" ry="2.872" transform="rotate(-21.544 20.54 30.004)" />
                            <ellipse cx="24.1" cy="33.5" fill="#546e7a" rx="3.6" ry="2.894" transform="rotate(-62.361 24.1 33.5)" />
                            <path fill="#aed581" d="M32.26,14H15.74c0.13-0.26,0.27-0.51,0.44-0.76L21,6l3-1l3,1l4.82,7.24 C31.99,13.49,32.13,13.74,32.26,14z" />
                            <path fill="#33691e" d="M27,1h-6c-0.552,0-1,0.448-1,1v4h8V2C28,1.448,27.552,1,27,1z" />
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Zeytin ve Zeytin Yağı
                    </div>
                </Link>
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Recel">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="72" height="72">
                            <path fill="#ec407a" d="M34,43H14c-2.2,0-4-1.8-4-4V15h28v24C38,41.2,36.2,43,34,43z" />
                            <path fill="#c2185b" d="M33.5,43h-19C12,43,10,41,10,38.5l0,0c0-2.5,0-3.5,0-3.5h28c0,0,0,1,0,3.5l0,0C38,41,36,43,33.5,43z" />
                            <path fill="#c2185b" d="M13 30A3 3 0 1 0 13 36A3 3 0 1 0 13 30Z" />
                            <path fill="#c2185b" d="M17 33A3 3 0 1 0 17 39A3 3 0 1 0 17 33Z" />
                            <path fill="#c2185b" d="M22.5 31A3.5 3.5 0 1 0 22.5 38A3.5 3.5 0 1 0 22.5 31Z" />
                            <path fill="#c2185b" d="M26 34A2 2 0 1 0 26 38 2 2 0 1 0 26 34zM32 22A2 2 0 1 0 32 26 2 2 0 1 0 32 22zM21.5 24A1.5 1.5 0 1 0 21.5 27 1.5 1.5 0 1 0 21.5 24zM27.5 26A1.5 1.5 0 1 0 27.5 29 1.5 1.5 0 1 0 27.5 26z" />
                            <path fill="#c2185b" d="M29.5 33A2.5 2.5 0 1 0 29.5 38A2.5 2.5 0 1 0 29.5 33Z" />
                            <path fill="#c2185b" d="M34 31A4 4 0 1 0 34 39A4 4 0 1 0 34 31Z" />
                            <path fill="#d81b60" d="M37,19C37,19,37,19,37,19c-3.2,0-6-1.3-6-3c0,2.2-3,4-7,4c-4,0-7-1.8-7-4c0,1.7-2.8,3-6,3 c0,0,0,0,0,0v2c0,0,0,0,0,0c2,0,4.1-0.5,5.6-1.4c1.6,1.5,4.2,2.4,7.3,2.4s5.7-0.9,7.3-2.4c1.5,0.9,3.6,1.4,5.6,1.4c0,0,0,0,0,0V19z" />
                            <path fill="#90caf9" d="M34,44H14c-2.8,0-5-2.2-5-5V14h30v25C39,41.8,36.8,44,34,44z M11,16v23c0,1.7,1.3,3,3,3h20 c1.7,0,3-1.3,3-3V16H11z" />
                            <path fill="#ffe0b2" d="M17,16c0,2.2,3,4,7,4s7-1.8,7-4c0,1.7,2.8,3,6,3c1.6,0,3-0.3,4-0.9c1.2-0.7,1.3-2.4,0.2-3.2L37,11H11 l-4.2,3.9c-1.1,0.9-1,2.6,0.2,3.2c1,0.5,2.5,0.9,4,0.9C14.2,19,17,17.7,17,16z" />
                            <path fill="#64b5f6" d="M37 19v2c.7 0 1.4-.1 2-.2v-2C38.4 18.9 37.7 19 37 19zM11 19c-.7 0-1.4-.1-2-.2v2c.6.1 1.3.2 2 .2V19z" />
                            <path fill="#8d6e63" d="M37,11H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h26c0.6,0,1,0.4,1,1S37.6,11,37,11z" />
                            <path fill="#a1887f" d="M37,17c-0.4,0-0.7-0.2-0.9-0.6l-3-6c-0.2-0.5,0-1.1,0.4-1.3c0.5-0.2,1.1,0,1.3,0.4l3,6 c0.2,0.5,0,1.1-0.4,1.3C37.3,17,37.2,17,37,17z" />
                            <path fill="#a1887f" d="M33,15c-0.1,0-0.2,0-0.2,0c-0.5-0.1-0.9-0.7-0.7-1.2l1-4c0.1-0.5,0.7-0.9,1.2-0.7 c0.5,0.1,0.9,0.7,0.7,1.2l-1,4C33.9,14.7,33.4,15,33,15z" />
                            <path fill="#ffd180" d="M37,9H11c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h26c0.6,0,1,0.4,1,1v3C38,8.6,37.6,9,37,9z" />
                        </svg>    
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Marmelat ve Reçel
                    </div>
                </Link>                    
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Bal">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 48 48">
                            <path fill="#FFD54F" d="M30,13H18c-6.627,0-12,5-12,12s10,17,10,17h16c0,0,10-10,10-17S36.627,13,30,13L30,13z" />
                            <path fill="#FFD54F" d="M12 12.001H36V16.000999999999998H12z" />
                            <path fill="#FF9800" d="M16.844,40h14.312C34.072,36.932,40,29.718,40,25c0-2.781-1.028-5.223-2.737-7H10.738C9.028,19.777,8,22.219,8,25C8,29.729,13.928,36.936,16.844,40z" />
                            <path fill="#FFC107" d="M22.749 30L21 27 22.749 24 26.251 24 28 27 26.251 30zM29.749 34L28 31 29.749 28 33.251 28 35 31 33.251 34zM22.749 38L21 35 22.749 32 26.251 32 28 35 26.251 38zM15.749 34L14 31 15.749 28 19.251 28 21 31 19.251 34zM15.749 26L14 23 15.749 20 19.251 20 21 23 19.251 26z" />
                            <path fill="#795548" d="M37,12V8c0-1.104-0.896-2-2-2H13c-1.104,0-2,0.896-2,2v4H37z" />
                            <path fill="#5D4037" d="M11 11.001H37V13H11z" />
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Bal Çeşitleri
                    </div>
                </Link>
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Helva">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="72" height="72">
                            <path fill="#755da5" d="M90 53.5A12.5 12.5 0 1 0 90 78.5A12.5 12.5 0 1 0 90 53.5Z" />
                            <path fill="#755da5" d="M77 75.5A12.5 12.5 0 1 0 77 100.5A12.5 12.5 0 1 0 77 75.5Z" />
                            <path fill="#a694d3" d="M64 53.5A12.5 12.5 0 1 0 64 78.5 12.5 12.5 0 1 0 64 53.5zM64 97.5A12.5 12.5 0 1 0 64 122.5 12.5 12.5 0 1 0 64 97.5z" />
                            <path fill="#64578c" d="M76.4,111.6c0.8-6.7-8.2-9.3-11.2-3.3c-0.3,0.6-0.7,1.2-1.2,1.7c-0.5,0.5-1,0.9-1.6,1.2c-5.9,3.2-3.2,12.1,3.4,11.2c0.1,0,0.2,0,0.3,0c5.3-0.8,9.5-5.1,10.3-10.3C76.4,111.9,76.4,111.7,76.4,111.6z" />
                            <path fill="#66518e" d="M92,78.3c5.3-0.8,9.6-5.1,10.4-10.4c0-0.1,0-0.2,0-0.2c0.6-4.7-6.2-6.5-7.9-2c-1,2.6-2.8,4.7-5.1,6.1c-3.3,2-1.4,7.2,2.4,6.7C91.9,78.4,91.9,78.4,92,78.3z" />
                            <path fill="#594a7f" d="M89.4,89.8c0-0.1,0-0.2,0-0.3c0.7-6-7.8-7.7-9.7-2c-0.7,2.2-2,4.3-3.9,6.4c-2.4,2.7,0,7,3.5,6.4c0.1,0,0.1,0,0.2,0C84.6,99.3,88.6,95,89.4,89.8z" />
                            <path fill="#ccc4f7" d="M38 53.5A12.5 12.5 0 1 0 38 78.5A12.5 12.5 0 1 0 38 53.5Z" />
                            <path fill="#fff" d="M32.6,65.4c-0.6,0-1.2-0.2-1.7-0.5c-1.4-0.9-1.7-2.8-0.8-4.2c1.8-2.6,4.7-4.2,7.9-4.2c1.7,0,3,1.3,3,3s-1.3,3-3,3c-1.2,0-2.2,0.6-2.9,1.5C34.5,64.9,33.6,65.4,32.6,65.4z" />
                            <path fill="#ccc4f7" d="M77 31.5A12.5 12.5 0 1 0 77 56.5A12.5 12.5 0 1 0 77 31.5Z" />
                            <path fill="#ccc4f7" d="M51 31.5A12.5 12.5 0 1 0 51 56.5A12.5 12.5 0 1 0 51 31.5Z" />
                            <path fill="#fff" d="M44.5,47c-1.7,0-3-1.3-3-3c0-5.2,4.3-9.5,9.5-9.5c1.7,0,3,1.3,3,3s-1.3,3-3,3c-1.9,0-3.5,1.6-3.5,3.5C47.5,45.7,46.2,47,44.5,47z" />
                            <path fill="#a694d3" d="M51 75.5A12.5 12.5 0 1 0 51 100.5A12.5 12.5 0 1 0 51 75.5Z" />
                            <path fill="#444b54" d="M64 81.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S72.5 81.5 64 81.5zM64 56.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S69.2 56.5 64 56.5zM64 125.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S72.5 125.5 64 125.5zM64 100.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S69.2 100.5 64 100.5z" />
                            <path fill="#444b54" d="M38 81.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S46.5 81.5 38 81.5zM38 56.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S43.2 56.5 38 56.5zM77 59.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S85.5 59.5 77 59.5zM77 34.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S82.2 34.5 77 34.5z" />
                            <path fill="#444b54" d="M51 59.5c-8.5 0-15.5-7-15.5-15.5 0-3.5 1.2-7 3.4-9.7 3-3.7 7.4-5.8 12.1-5.8 8.5 0 15.5 7 15.5 15.5S59.5 59.5 51 59.5zM51 34.5c-2.9 0-5.6 1.3-7.4 3.5-1.4 1.7-2.1 3.8-2.1 6 0 5.2 4.3 9.5 9.5 9.5s9.5-4.3 9.5-9.5S56.2 34.5 51 34.5zM51 103.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S59.5 103.5 51 103.5zM51 78.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S56.2 78.5 51 78.5zM90 81.5c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5S98.5 81.5 90 81.5zM90 56.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5S95.2 56.5 90 56.5z" />
                            <path fill="#444b54" d="M77,103.5c-8.5,0-15.5-7-15.5-15.5s7-15.5,15.5-15.5s15.5,7,15.5,15.5S85.5,103.5,77,103.5z M77,78.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S82.2,78.5,77,78.5z" />
                            <path fill="#fff" d="M61 63.5c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.4-.4-.6-.6-.9-1.3-.9-2.1s.3-1.6.9-2.1c.7-.7 1.7-1 2.7-.8.2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.4.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .8-.3 1.6-.9 2.1-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C61.4 63.5 61.2 63.5 61 63.5zM74 41.5c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.4-.4-.6-.6-.9-1.3-.9-2.1s.3-1.6.9-2.1c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1c-.1.1-.3.3-.4.4-.2.1-.3.2-.5.3-.2.1-.4.1-.6.2C74.4 41.5 74.2 41.5 74 41.5z" />
                            <path fill="#444b54" d="M64,47c-1.7,0-3-1.3-3-3V29.1c0-3.6,0.5-7.2,1.5-10.7c1.6-5.8,4.6-11.2,8.7-15.6c1.1-1.2,3-1.3,4.2-0.2c1.2,1.1,1.3,3,0.2,4.2c-3.4,3.8-5.9,8.3-7.3,13.2c-0.8,2.9-1.3,6-1.3,9V44C67,45.7,65.7,47,64,47z" />
                            <path fill="#70e9d4" d="M75.2,20.2c0,0,6.3-22.5,30-12.8C105.2,7.3,98.3,28.1,75.2,20.2z" />
                            <path fill="#444b54" d="M85.1,25c-3.4,0-7.1-0.7-10.9-2c-1.6-0.5-2.4-2.2-1.9-3.8c0.5-1.6,2.2-2.4,3.8-1.9c6.5,2.2,12.1,2.2,16.8-0.1c4.3-2.1,6.8-5.7,8.2-8.2c-5.6-1.7-10.4-1.5-14.4,0.7c-1.5,0.8-3.3,0.3-4.1-1.2c-0.8-1.4-0.3-3.3,1.2-4.1c6.3-3.5,13.9-3.5,22.5,0c1.5,0.6,2.2,2.2,1.7,3.7c-0.1,0.4-3.3,9.8-12.4,14.3C92.4,24.2,88.9,25,85.1,25z" />
                            <g>
                                <path fill="#444b54" d="M57.2,22H38c-1.7,0-3-1.3-3-3s1.3-3,3-3h19.2c1.7,0,3,1.3,3,3S58.9,22,57.2,22z" />
                            </g>
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Tahin Pekmez ve Helva
                    </div>
                </Link>
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Kahvaltilik">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="72" height="72">
                            <path fill="#b71c1c" d="M31,18c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S31,16.3,31,18z" />
                            <path fill="#b71c1c" d="M28 17c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3S28 15.3 28 17zM20 17c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3S20 15.3 20 17z" />
                            <path fill="#b71c1c" d="M17,18c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S17,16.3,17,18z" />
                            <path fill="#b71c1c" d="M14 19c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3S14 17.3 14 19zM34 19c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3S34 17.3 34 19zM24 16c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3S24 14.3 24 16z" />
                            <path fill="#90caf9" d="M41.5,25c-0.6,0-1-0.4-1-1s0.4-1,1-1c1.9,0,3.5-1.6,3.5-3.5S43.4,16,41.5,16S38,17.6,38,19.5v2.6 c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2.6c0-3,2.5-5.5,5.5-5.5s5.5,2.5,5.5,5.5S44.5,25,41.5,25z" />
                            <path fill="#90caf9" d="M25,36h-5.9c-7.8,0-12-5.9-13.1-11.1C5,20.1,1,17,1,17v-2c0,0,9.1,3,20.8,3s16.4-3,18.1-3.7 c0,0-3.4,2.8-1.8,7.6C38,29.7,32.8,36,25,36z" />
                            <path fill="#64b5f6" d="M14,35h16v2H14V35z" />
                            <path fill="#90caf9" d="M30,37H14c-1.1,0-2,0.9-2,2v1h20v-1C32,37.9,31.1,37,30,37z" />
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Kahvaltılık Ürünler
                    </div>
                </Link>
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Sarkuteri">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 48 48">
                            <path fill="#F44336" d="M5.399,36.264c0.482,3.715,2.973,6.532,6.494,6.532c17.25,0,26.365-8.233,26.365-8.233c0.4-0.399,5.516-5.104,5.516-10.443c0-4.709-3.651-9.019-6.981-11.854c-2.705-2.301-5.495-5.104-9.33-5.104c-6.81,0-10.751,2.589-12.313,7.214C17.962,30.375,4.462,29.063,5.399,36.264z" />
                            <path fill="#FF8A80" d="M24.9 25.907c-.02-.036-.038-.073-.054-.113-.204-.515.047-1.099.562-1.303.516-.205 1.099.047 1.304.562.568 1.432.844 2.846.794 4.352-.047 1.481-.614 3.027-1.691 4.068-.549.53-1.124.875-1.646 1.287-.535.405-1.062.822-1.572 1.258-.512.435-1.008.887-1.469 1.373-.438.493-.936.994-1.104 1.651.09-.683.533-1.255.916-1.808.407-.549.855-1.067 1.318-1.568.463-.502.943-.986 1.435-1.461.488-.484 1.028-.92 1.395-1.389.762-.967 1.035-2.186.928-3.396-.099-1.199-.528-2.458-1.094-3.473L24.9 25.907zM32.568 22.012c-.063-.555.335-1.055.891-1.118.555-.063 1.056.335 1.119.89l.001.016c.083.731.073 1.373.027 2.049-.049.667-.158 1.328-.334 1.969-.346 1.277-1.045 2.496-2.081 3.224.802-.979 1.09-2.198 1.091-3.368 0-.59-.062-1.175-.175-1.75-.11-.564-.272-1.161-.47-1.641C32.604 22.201 32.58 22.107 32.568 22.012zM30.641 16.223c.071-.048.147-.091.229-.127.759-.341 1.649-.001 1.99.759.341.76.001 1.651-.759 1.992-1.301.582-2.539.976-3.934 1.197-.691.1-1.412.148-2.166.05-.767-.091-1.511-.38-2.138-.846-.637-.47-1.085-1.137-1.34-1.783-.26-.651-.383-1.288-.506-1.899-.119-.613-.238-1.199-.452-1.767-.222-.541-.466-1.166-1.042-1.423.641.127 1.057.725 1.41 1.236.354.536.623 1.135.873 1.704.482 1.148 1.059 2.196 1.928 2.524.88.365 1.854.267 2.908-.092 1.022-.344 2.096-.909 2.972-1.51L30.641 16.223z" />
                            <path fill="#FF8A80" d="M34.086 16.511c-.067 1.14-.001 2.422.187 3.607.192 1.196.524 2.369 1.124 3.352.314.511.646.85 1.191 1.331.51.485.965 1.032 1.289 1.674.327.633.466 1.379.354 2.063-.105.688-.423 1.286-.769 1.836.237-.606.422-1.242.395-1.858-.022-.617-.254-1.184-.62-1.648-.361-.467-.838-.859-1.344-1.182-.501-.287-1.207-.754-1.678-1.291-.992-1.086-1.632-2.385-2.115-3.692-.478-1.33-.812-2.634-1.012-4.09L34.086 16.511zM26.106 31.072c.024-.276.268-.48.544-.457.277.023.482.267.458.543l-.001.012c-.074.871-.066 1.793.063 2.676.066.443.17.883.345 1.293.176.406.434.799.842 1.028-.444-.149-.799-.513-1.062-.908-.264-.399-.457-.842-.611-1.292-.306-.908-.484-1.833-.577-2.803C26.105 31.135 26.105 31.104 26.106 31.072z" />
                            <path fill="#FBE9E7" d="M30.649,15.188c2.367-8.512-7.609-8.141-9.734-7.766C25.447,6,29.881,4.556,37.257,12.681c7.062,6.438,8.564,10.882,7.331,14.256c0.937-5.875-8.309-15.588-11.063-9.5c-4.75,10.5-21.736,17.798-22.875,18.5c-1.219,0.75-2.453-0.687-1.5-1.5C10.226,33.521,27.399,26.875,30.649,15.188z" />
                            <path fill="#FFCCBC" d="M4.152,37.82l0.041-0.002c0.356,3.574,3.041,6.164,6.957,6.164c19.542,0,28-8.795,28-8.795c0.428-0.426,5.875-5.437,5.875-11.125c0-5.016-3.891-9.606-7.438-12.625C34.706,8.985,31.733,6,27.649,6c-4.452,0-8.568,1.857-11.531,4.719l0.002,0.023c-2.404,2.351-2.103,5.048-1.834,7.429c0.256,2.264,0.498,4.403-1.181,6.597c-1.258,1.645-2.673,2.842-4.04,3.998C6.372,31.046,3.829,33.199,4.152,37.82z M6.149,37c0-0.299,0.012-0.561,0.037-0.811c0.023-0.207,0.059-0.406,0.1-0.6c0.104-0.447,0.278-0.912,0.551-1.521c0.74-1.401,2-2.488,3.521-3.776c1.382-1.169,2.946-2.495,4.336-4.31c2.169-2.833,1.856-5.598,1.58-8.037c-0.257-2.285-0.458-4.093,1.229-5.756C20.144,9.566,23.734,8,27.649,8c2.916,0,5.255,2.028,7.73,4.175c0.306,0.264,0.608,0.527,0.911,0.785c2.515,2.14,6.733,6.428,6.733,11.102c0,4.141-3.623,8.096-5.181,9.605l-0.056,0.056c-0.464,0.404-9.056,8.485-26.639,8.26C8.315,42.007,6.149,39.84,6.149,37z" />
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Şarküteri
                    </div>
                </Link>
                <Link className="group flex flex-col m-1 md:mx-4 w-2/12 md:w-auto md:bg-transparent" to="/Urunlerimiz/Baharat">
                    <div className="flex justify-center align-middle bg-categoriesUp p-0 md:p-4 bg-center md:rounded-full rounded-3xl group-hover:bg-gray-800 duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 48 48">
                            <path fill="#546E7A" d="M26,5.5C26,6.328,25.328,7,24.5,7h-1C22.672,7,22,6.328,22,5.5v-1C22,3.672,22.672,3,23.5,3h1C25.328,3,26,3.672,26,4.5V5.5z" />
                            <path fill="#BBDEFB" d="M32,26.5c0,0.828-0.672,1.5-1.5,1.5h-13c-0.828,0-1.5-0.672-1.5-1.5v-9c0-0.828,0.672-1.5,1.5-1.5h13c0.828,0,1.5,0.672,1.5,1.5V26.5z" />
                            <path fill="#64B5F6" d="M23 16H25V27H23z" />
                            <path fill="#5D4037" d="M32,24.5c0-0.828-0.672-1.5-1.5-1.5h-13c-0.828,0-1.5,0.672-1.5,1.5v12c0,0.828,0.672,1.5,1.5,1.5h13c0.828,0,1.5-0.672,1.5-1.5V24.5z" />
                            <path fill="#4E342E" d="M23.5 28A1.5 1.5 0 1 0 23.5 31 1.5 1.5 0 1 0 23.5 28zM29.5 21A1.5 1.5 0 1 0 29.5 24 1.5 1.5 0 1 0 29.5 21zM19.5 31A1.5 1.5 0 1 0 19.5 34 1.5 1.5 0 1 0 19.5 31zM27.5 33A1.5 1.5 0 1 0 27.5 36 1.5 1.5 0 1 0 27.5 33zM23.5 32A1.5 1.5 0 1 0 23.5 35 1.5 1.5 0 1 0 23.5 32zM28.5 28A1.5 1.5 0 1 0 28.5 31 1.5 1.5 0 1 0 28.5 28z" />
                            <path fill="#4E342E" d="M17.5 32A1.5 1.5 0 1 0 17.5 35 1.5 1.5 0 1 0 17.5 32zM18.5 20A1.5 1.5 0 1 0 18.5 23 1.5 1.5 0 1 0 18.5 20zM19.5 23A1.5 1.5 0 1 0 19.5 26 1.5 1.5 0 1 0 19.5 23z" />
                            <g>
                                <path fill="#FF5722" d="M20.5 21c-.197 0-.385.041-.558.11C19.976 21.235 20 21.364 20 21.5c0 .631-.391 1.168-.942 1.39C19.23 23.528 19.808 24 20.5 24c.828 0 1.5-.672 1.5-1.5S21.328 21 20.5 21zM24.5 34A1.5 1.5 0 1 0 24.5 37 1.5 1.5 0 1 0 24.5 34zM29.5 25A1.5 1.5 0 1 0 29.5 28 1.5 1.5 0 1 0 29.5 25zM20.582 35.082A1.5 1.5 0 1 0 20.582 38.082 1.5 1.5 0 1 0 20.582 35.082zM19.5 26A1.5 1.5 0 1 0 19.5 29 1.5 1.5 0 1 0 19.5 26z" />
                            </g>
                            <g>
                                <path fill="#F0F4C3" d="M18.5 23c-.631 0-1.168-.391-1.39-.942C16.472 22.23 16 22.808 16 23.5c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5c0-.197-.041-.385-.11-.558C18.765 22.976 18.636 23 18.5 23zM25.5 26A1.5 1.5 0 1 0 25.5 29 1.5 1.5 0 1 0 25.5 26zM28.5 35A1.5 1.5 0 1 0 28.5 38 1.5 1.5 0 1 0 28.5 35z" />
                            </g>
                            <g>
                                <path fill="#795548" d="M17.5 28A1.5 1.5 0 1 0 17.5 31 1.5 1.5 0 1 0 17.5 28zM21.5 25A1.5 1.5 0 1 0 21.5 28 1.5 1.5 0 1 0 21.5 25zM26.5 30A1.5 1.5 0 1 0 26.5 33 1.5 1.5 0 1 0 26.5 30zM29.5 32A1.5 1.5 0 1 0 29.5 35 1.5 1.5 0 1 0 29.5 32zM26.5 22A1.5 1.5 0 1 0 26.5 25 1.5 1.5 0 1 0 26.5 22zM21.5 30.5A1.5 1.5 0 1 0 21.5 33.5 1.5 1.5 0 1 0 21.5 30.5z" />
                            </g>
                            <g>
                                <path fill="#558B2F" d="M23.5 22A1.5 1.5 0 1 0 23.5 25 1.5 1.5 0 1 0 23.5 22zM18.5 32.84A1.5 1.5 0 1 0 18.5 35.84 1.5 1.5 0 1 0 18.5 32.84zM30.5 29A1.5 1.5 0 1 0 30.5 32 1.5 1.5 0 1 0 30.5 29zM30.5 22A1.5 1.5 0 1 0 30.5 25 1.5 1.5 0 1 0 30.5 22z" />
                            </g>
                            <g>
                                <path fill="#90A4AE" d="M32 43.5c0 .828-.672 1.5-1.5 1.5h-13c-.828 0-1.5-.672-1.5-1.5v-4c0-.828.672-1.5 1.5-1.5h13c.828 0 1.5.672 1.5 1.5V43.5zM32 12.444C32 14.408 30.408 16 28.444 16h-8.889C17.592 16 16 14.408 16 12.444l0 0c0-1.963 1.925-5.443 8-5.443C30.074 7.001 32 10.481 32 12.444L32 12.444z" />
                            </g>
                        </svg>
                    </div>
                    <div className="text-center text-xs md:text-sm">
                        Kuru Gıda ve Baharatlar
                    </div>
                </Link>                    
            </div>
        </div>
    )
};